import { render, staticRenderFns } from "./clinic.vue?vue&type=template&id=ed3d6430&scoped=true&"
import script from "./clinic.vue?vue&type=script&lang=js&"
export * from "./clinic.vue?vue&type=script&lang=js&"
import style0 from "./clinic.vue?vue&type=style&index=0&id=ed3d6430&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3d6430",
  null
  
)

export default component.exports